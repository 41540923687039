(function($){
	
	$.fn.extend({
	    
	    animateCss: function (animationName) {
        var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        this.addClass('animated ' + animationName).one(animationEnd, function() {
            $(this).removeClass('animated ' + animationName);
        });
        return $(this);
	    }
	});

	const helpers = {
		setVideoBg: function (){
			const bgVideo = $('.js--video-bg').YTPlayer({
		    fitToBackground: true,
		    videoId: 'vbuCau0i1uI',
		    repeat: true,
		   	playerVars: {
		      modestbranding: 0,
		      branding: 0,
		      rel: 0
		    }
			});
			window.obtApp.video = bgVideo;
		},
		removeVideoBg: function(){
			$('.js--video-bg .ytplayer-container').fadeOut('fast', function(){
				$('.ytplayer-container, .ytplayer-shield').remove()
			});
		}
	};	


	$(function () {
		routeAnimations();

		if($(window).width() > 780){
			helpers.setVideoBg();
		}
		$('.nav-footer__item a').on('click', function(e){
			const $self = $(this);
			e.preventDefault();
			ani.actOutro( ()=>{window.location = $self.attr('href') });
		});
	});

	$(window).on('resize', ()=>{
		if($(window).width() > 780){
			if($('.js--video-bg .ytplayer-container').length === 0){
				helpers.setVideoBg();
			}
		}else{
			helpers.removeVideoBg();
		}
	});

	const ani = {

		actIntro: function startMain(){
			var tl = new TimelineLite({delay:1});
			tl.from($('.swiper-wrapper'), 1.2, {opacity:0} );
			tl.from($('.act-slide__overlay'), 0.5, {opacity:0} );
			tl.from($('.act-slide__body'), 1, {opacity:0} );
			tl.from($('.act-slide__body'), 1.2, {marginLeft: "50%", x: "-25%"} );
			tl.from($('.act-slide__read-more'), .4, {opacity:0} );

			tl.from($('.slide-nav'), .8, {opacity:0, bottom: -150}, 'mainNav');
			tl.from($('.header'), .8, {top: -300, opacity:0}, 'mainNav');
			tl.from($('.nav-footer__item'), .8, {top: -300, opacity:0}, 'mainNav');
		},

		actOutro: function(cb) {
			var tl = new TimelineLite();
			tl.to($('.nav-footer__item--right'), 0.5, {x: 20, opacity: 0}, "mainNav");
			tl.to($('.nav-footer__item--left'), 0.5, {x: -20, opacity: 0}, "mainNav" );
			tl.to($('.header'), .5, {top: -300, opacity:0}, 'mainNav');
			tl.to($('.act-slide__body'), .5, {opacity:0}, 'mainNav');
			tl.to($('.slide-nav'), .4, {opacity:0, bottom: 20}, 'mainNav');
			tl.to($('.act__main'), .3, {opacity:0});
			setTimeout(cb, 300);
		}
	}


	
	function isTemplate(templateName){
		return $('body').hasClass(templateName);
	}

	function routeAnimations(){
		if($(window).width() > 780){
			if (isTemplate('template-act')){
				// ani.actIntro();
			}
		}
	}


})(jQuery);